.labservice-container {
  display: flex;
}

.side-menu {
  width: 300px;
  border-right: 1px solid blue;
  height: 100vh;
}

.request-lab-container .add-location{
  cursor: pointer;
}

.request-lab-container {
  margin-left: 15%;
  margin-right: 15%;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 5em;
  margin-top: 1em;
  max-width: 600px;
}

@media (max-width: 600px){
  .request-lab-container {
    width: 100%;
   margin: 0;
  }
}

.request-lab-container .welcome {
  background: linear-gradient(90deg, #FC9E4F 0%, #3DD6DB 100%);
  /* background:#6EC1F2; */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  font-weight: 600;
  font-size: 45px;
}


.request-lab-container p {
  margin-top: 0.8em;
  font-size: 16px;
  font-weight: 400;
}

.request-lab-container p span,.update-link {
  font-weight: 600;
  color:  #044389;
}

.request-lab-container .heading2 {
  font-weight: 500;
  font-size: 2rem;
  margin-top: 1.5em;
  
}
.request-lab-container .heading3 {
  font-weight: 600;
  font-size: 1.55rem;
  margin-top: 1.5em;
  margin-bottom: 1rem;
}

.request-lab-container .heading4 {
  font-weight: 600;
  font-size: 1.2rem;
  margin-top: 3.5rem;
  margin-bottom: -0.9rem;
}

/* 
.location-card {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #044389;
  border-radius: 10px;
  padding: 10px;
  margin-top: 1.2em;
} */
.location-card {
  position: relative;
  display: flex;
  flex-direction: column; /* Stack the content vertically */
  background-color: #044389;
  border-radius: 10px;
  padding: 10px;
  margin-top: 1.2em;
}

.location-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit; 
  padding: 3px; /* This should be the same as the border width */
  background: linear-gradient(to right, #FC9E4F, #3DD6DB);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude; /* This is for Firefox */
  pointer-events: none; /* Make sure this pseudo-element does not capture mouse events */
}

.location-card > * {
  position: relative; 
  justify-items: center;
  align-content: center;
  padding: 1% 5%;
  /* Make the content position relative to the parent */
}

.location-card .loc1 {
  color: #FC9E4F;
  font-weight: 500;
  font-size: 30px;

}

.location-card .loc2 {
  font-size: 15px;
  font-weight: 400;

}

/* .location-card .get-started {
  color: #FC9E4F;
  font-weight: 600;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  
} */

.location-card .get-started {
  color: #FC9E4F;
  font-weight: 600;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0%;
}

.center-button {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  width: 100%;
}
.get-started img {
  margin-left: 10px;
  width: 12px;
  display:none;
}

.request-lab-container p.note span{
  font-weight: bold;
}

.request-lab-container p.note{
  font-size: 13px;
}

.note {
  display: none;
}

/* Phone media query */

@media (max-width: 600px){
 
  .request-lab-container .welcome {
    font-size: 10vw;
    line-height: 1.2;
  }

  .request-lab-container p{
    font-size: 0.9rem;
  }

  .request-lab-container .heading2{
    font-size: 30px;
  }

  .location-card .loc1{
    font-size: 1.2rem;
  }

  .location-card .loc2{
    font-size: 0.8rem;
  }

  .location-card .get-started{
    font-size: 1rem;
  }


}

/* Request lab servic 2 */

.request-lab-container .nav-back div{
  color:#FC9E4F;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;

}
.request-lab-container .nav-back div img{
  margin-right: 5px;

}

.request-lab-container .item-list-container {
  display: flex;
  justify-content: center;
  font-size: 0.8rem;
}



.request-lab-container .item-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: 0;
  list-style: none;
  width: 100%;
  max-width: 800px;
  
}

.request-lab-container .item-list li {
  flex: 1 0 50%; 
  padding: 10px;
  width: 100%;
 
}
.request-lab-container .item-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer; 
  padding: 10px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  background-color: #fff;
  transition: background-color 0.3s ease, transform 0.3s ease;

}

.request-lab-container .item-link:hover {
  background-color: #FC9E4F; 
  color: #6104EF;
  transform: scale(1.02);
  
}

.request-lab-container .icon-container {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.request-lab-container  .icon {
  width: 100%;
  height: 100%;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.request-lab-container .item-link:hover .icon-container .icon {
  transform: rotate(60deg);

}

@media (max-width:600px) {
  .request-lab-container .item-list{
    grid-template-columns: 1fr; 
    gap: 1px; 
    width: 100%;
    
    
  }

  .request-lab-container .item-link{
    padding: 18px 10px;
    
  }

  
}

/* Screening questions */
.questions{
  margin-bottom: 5em;
}

.questions ul {
  list-style: none;
  padding: 0;

}

.questions li {
  margin-bottom: 30px;
  
}

.questions div {
  margin-bottom: 5px;
}


.questions input[type="checkbox"] {
  margin-right: 5px;
}

.questions label {
  margin-right: 15px;
  font-size: 0.875rem;
}

.questions .qn,
.questions p.note{
  font-size: 15px;
}
.questions button{
  font-size: 20px;
  background-color: #FC9E4F;
  color: #fff;
  border-radius: 5px;
  width: 100%;
  height: 70px;
  font-weight: 700px;
  margin-top: 1rem;
}

.question-container {
  width: 100%;
}

.question-container .quetion-p{
  font-size: 1.3rem;
}

.question-container .question-number {
  font-weight: bold;
  font-size: 1.3rem; 
  color: black;
}

.question-container .yesNo-btn-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2em;
  margin-bottom: 2em;
}

.question-container .yesNo-btn-container button{
  position: relative;
  padding: 15px 20px;
  background-color: #F5F5F5;
  width: 100%;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.3s ease;
  z-index: 1;
}

.question-container .yesNo-btn-container > *:not(:last-child){
  margin-bottom: 1rem;
}

.question-container .yesNo-btn-container button:hover{
  background-color: #E8D9FE;
  color: #FC9E4F;

}



/* request labsevice 4 */


.request-lab-container .tip-container{
  position: relative;
  border: solid 2px #FC9E4F ;
  padding: 15px 25px;
  border-radius: 10px;
  margin-top: 1rem;


}

.request-lab-container .tip-container .close-tip{
  position: absolute;
  top: -15px;
  right: -15px;
}

.request-lab-container .tip-container .close-tip .img-close-tip{
  width: 36px;
  color:#FC9E4F;

}

.request-lab-container .tip-container .sel-note-heading{
  color: #FC9E4F;
  font-weight: 600
}

.request-lab-container .sel-test{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2em;
  margin-bottom: 2em;
}

.request-lab-container .sel-test > *:not(:last-child){
  margin-bottom: 1rem;
}


.request-lab-container .sel-test button{
  position: relative;
  padding: 20px 20px;
  background-color: #F5F5F5;
  
  width: 100%;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.3s ease;
  z-index: 1;
  /* padding-top: 25px;
  padding-bottom: 28px; */
}

.request-lab-container .sel-test button:hover::before,
.request-lab-container .sel-test button:focus::before,
.request-lab-container .sel-test button:active::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit; 
  
  padding: 3px; /* This should be the same as the border width */
  background: linear-gradient(to right, #FC9E4F, #3DD6DB);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude; /* This is for Firefox */
  pointer-events: none; /* Make sure this pseudo-element does not capture mouse events */
}

.request-lab-container .sel-test button:hover,
.request-lab-container .sel-test button:focus,
.request-lab-container .sel-test button:active {
  color: #FC9E4F;
  background-color: #044389;
}



.request-lab-container p.sel-note{
  font-size: 15px;
  font-weight: 400;
}

/* request labservice 5 */

.request-lab-container p.av-dates{
  font-weight: 300;
  font-size: 20px;
}
.request-lab-container .calender-plugin{
  height: 200px;
  width: 100%;
  background-color: #044389;
}
.request-lab-container .slot-time{
  font-size: 20px;
  font-weight: 400;
  margin-top: 1em;
  
}
.request-lab-container .slot-time span{
  font-weight: 600;

}

.request-lab-container button.confirm{
  font-size: 25px;
  background-color: #FC9E4F;
  color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 89px;
  font-weight: 600px;
  margin-top: 2em;
}

/* Date input feild */

.date-input-container {
  display: flex;
  flex-direction:column;
  
}

.date-input {
  position: relative;
  display: flex;
  align-items: center;
}

.date-input-field {
  width: 100%;
  padding: 15px;
  border: 3px solid #D9D9D9;
  border-radius: 12px;
  color: #4e5152;
  font-size: .9rem;
  margin-top: 1rem;
  transition: border-color 0.3s ease
  
}

.date-input-field:focus {
  outline: none; 
  border-color: #FC9E4F;
 
}

.calendar-icon {
  position: absolute;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.formatted-date-display {
  margin-top: 4px;
  font-size: 14px;
  color: #FC9E4F; /* Adjust color as needed */
}

.date-input-field::placeholder {
  text-transform: uppercase;
}



/* Time Input field */

.time-input {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
 
}

.time-input-field {
  width: 100%;
  padding: 15px;
  border: 3px solid #D9D9D9;
  border-radius: 12px;
  color: #4e5152;
  font-size: .9rem;
  transition: border-color 0.3s ease
  
}

.time-input-field:focus {
  outline: none; 
  border-color: #FC9E4F;
}

.clock-icon {
  position: absolute;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;

}


/* Text input */
.message-field {
  width: 100%;
  margin-top: 1em;
}

.message-input {
  width: 100%;
  padding: 15px;
  border: 3px solid #D9D9D9;
  border-radius: 12px;
  color: #4e5152;
  font-size: .9rem;
  resize: vertical; 
  font-family: inherit; /* Match the font with the surrounding text */
  transition: border-color 0.3s ease
}

.message-input:focus{
  outline: none; 
  border-color: #FC9E4F;

}


/* request lab 7 */

.request-lab-container .submit-button {
  width: 100%;
  background-color: #e7d7ff;
  padding: 12px 5px;
  border-radius: 5px;
  margin-bottom:3rem;
  margin-top: 2rem;
  color: #FC9E4F;
  border: 3px solid #FC9E4F;
  transition: background-color 0.3s, border-color 0.3s;
}

.request-lab-container .submit-button:hover {
  background-color: #FC9E4F; 
  color: #e7d7ff; 
  border-color: #FC9E4F; 
}

.no-ring {
  --tw-ring-color: transparent !important; /* Ensure the ring color is transparent */
}


.error {
  color: #d9534f; /* Bootstrap's Danger color */
  background-color: rgba(217, 83, 79, 0.1); /* Faded red background */
  padding: 8px;
  border: 1px solid #d9534f;
  border-radius: 4px;
  margin-top: 8px;
}



/* Container styles */
.hiv-testing-container {
  width: 100%;
  /* height: 100vh; */
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 15%;
  margin-right: 15%;
 
}

/* Title and text styles */
.hiv-testing-title {
  font-size: 28px;
  font-weight: 500;
  color: #000;
}

.hiv-testing-subtitle {
  font-size: 18px;
  font-weight: 400;
}

.hiv-testing-section-title {
  font-size: 20px;
  font-weight: 500;
}

.hiv-testing-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px;
}

.hiv-testing-text-bold {
  font-weight: 400;
  font-size: 15px;
}

.hiv-testing-text-normal {
  font-weight: 400;
  font-size: 14px;
  color: #434343;
}

.hiv-testing-text-center {
  text-align: left;
  width: 60%;
  font-size: 14px;
}

/* Button styles */
.hiv-testing-button {
  background-color: #FC9E4F; /* Example primary color */
  color: #fff;
  font-weight: 500;
  padding: 12px;
  border-radius: 10px;
  text-align: center;
  width: 15%;
  /* margin-left: 15%; */
  /* margin-right: 15%; */
}

.hiv-testing-button:hover {
  background-color: #3dd6db; /* Example hover color */
}

/* Success message styles */
.hiv-testing-success-message {
  position: absolute;
  top: 15px;
}


/* Confirmation page styling */

.hiv-testing-container {

  margin-left: 10%; 
  margin-bottom: 100px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  max-width: 900px;
}

.navigation-wrapper {
  color: #FC9E4F;
  font-weight: 500;
  width: 10%;
}

.title-wrapper {
  width: 100%;
}

.title {
  font-size: 28px;
  font-weight: 500;
  color: #000;
  display: flex;
  gap: 10px;
}

.highlight-text {
  background: linear-gradient(90deg, #FC9E4F 0%, #3DD6DB 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  font-weight: 600;
}

.subtitle {
  font-size: 16px;
  color: #666;
}

.details-wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.details-section {
  background-color: #ffffff;
  border: 1px solid #eee2ff;
  border-radius: 5px;
  padding: 20px;
}

.section-title {
  font-size: 20px;
  font-weight: 700;
}

.details-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  margin-top: 10px;
}

.label {
  font-size: 15px;
  font-weight: 600;
  text-align: left;
}

.value {
  font-size: 15px;
  font-weight: 400;
  text-align: left;
}

.consent-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.consent-text {
  font-size: 14px;
  color: #666;
  text-align: left;
}

.submit-button-wrapper {
  display: flex;
  justify-content: flex-end;
  
}

/* .submit-button {
  background-color: #FC9E4F;
  color: #fff;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 5px;
  width: 50%;
  max-width: 45%;
} */
.submit-button {
width: 100%;
background-color: #e7d7ff;
padding: 12px 5px;
border-radius: 5px;
margin-bottom: 3rem;
margin-top: 2rem;
color: #FC9E4F;
border: 3px solid #FC9E4F;
transition: background-color 0.3s, border-color 0.3s;
}


 .submit-button:hover {
  background-color: #FC9E4F; 
  color: #e7d7ff; 
  border-color: #FC9E4F; 
}

.inactive{
  width: 100%;
  background:  #F4EDFE;
  padding: 12px 5px;
  border-radius: 5px;
  margin-top: 2rem;
  color: #E9E9E9;
  margin-bottom: 3rem;
  transition: background-color 0.3s, border-color 0.3s;
}
.error-message {
  color: #FF0000;
  font-size: 14px;
  text-align: left;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
}

.success-wrapper {
  position: absolute;
  top: 15px;
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
  .hiv-testing-container {
    margin-right: 0;
    padding: 00px;
    margin: 0;
    width: 100%;
  }
  .content-wrapper {
  
    gap: 20px;
    width: 100%;
  }


  .details-grid {
    grid-template-columns: 1fr;
  }

  .title {
    font-size: 24px;
  }

  .highlight-text {
    font-size: 26px;
  }

  .subtitle {
    font-size: 14px;
  }

  .section-title {
    font-size: 18px;
  }

  .label{
    font-size: 14px;
    font-weight: 700;
    
  }

  .label, .value {
    font-size: 14px;
    
  }

  .consent-text {
    font-size: 12px;
  }

  .submit-button {
    width: 100%;
    text-align: center;
    padding: 12px;
    margin-bottom: 50px;
  }

  .submit-button-wrapper {
    justify-content: center;
  }
}


