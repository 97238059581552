@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  .topographyText{
    @apply font-Poppins not-italic tracking-normal
  }
}
/* Add this to your global CSS file (e.g., src/index.css) */
.phone-input-container .flag-dropdown {
  display: none !important; /* Use !important to override any inline styles */
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-text{
  background: var(--primary-gradient, linear-gradient(90deg, #8A3FFC 0%, #3DD6DB 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-family: Poppins;

font-style: normal;

line-height: normal;
}



@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Montserrat:wght@500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #0066CC; /* Blue */
  --secondary-color: #FF6600; /* Orange */
  --neutral-color: #333333; /* Dark Gray */
  --grayscale-color: #999999; /* Light Gray */
  --primary-gradient: linear-gradient(90deg, #8A3FFC 0%, #3DD6DB 100%);
}

@layer base {
  .topographyText {
    @apply font-Poppins not-italic tracking-normal;
  }
}

/* Remove unnecessary imports for other fonts */
.phone-input-container .flag-dropdown {
  display: none !important; /* Use !important to override any inline styles */
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif; /* Use Open Sans for body text */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--neutral-color); /* Neutral color for text */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.bg-text {
  background: var(--primary-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  line-height: normal;
}

/* Heading (h1, h2, h3) using Montserrat */
h1, h2, h3 {
  font-family: 'Montserrat', sans-serif;
  color: var(--primary-color); /* Primary color for headings */
}

/* Specific styling for h1 (primary heading) */
h1 {
  font-weight: 700; /* Bold */
}

/* Specific styling for h2 (subheading) */
h2 {
  font-weight: 600; /* Semi-bold */
}

/* Specific styling for h3 (secondary heading) */
h3 {
  font-weight: 500; /* Medium */
}

/* Body text using Open Sans Regular */
p, span, li, a {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400; /* Regular */
  color: var(--neutral-color);
}

/* Links */
a {
  color: var(--grayscale-color); /* Light gray for links */
  text-decoration: none;
}

/* Apply secondary color to emphasis text */
em {
  color: var(--secondary-color); /* Orange for emphasized text */
}

/* Buttons */
button {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600; /* Semi-bold */
  
}

/* Button hover effect */
/* button:hover {
  background-color: var(--secondary-color);
} */

/* Responsive font sizing for mobile */
@media (max-width: 768px) {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
  }

  p, span, li, a {
    font-size: 14px;
  }

  button {
    font-size: 14px;
  }
}
