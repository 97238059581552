.mobile-side-nav-custom {
    display: block;
    position: fixed;
    top: 0;
    left: -100%;
    bottom: 0;
    width: 80%;
    max-width: 384px;
    z-index: 9999;
    background-color: white;
    padding: 20px;
    transition: left 300ms ease-in-out;
    overflow-y: auto;
  }
  
  .mobile-side-nav-custom.mounted {
    left: 0;
  }
  
  .nav-content-custom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 22%;
  }
  
  .nav-logo-custom {
    width: 72px;
    height: 21.91px;
    margin-bottom: 20px;
  }
  
  .nav-section-title-custom {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  
  .nav-link-custom {
    margin-bottom: 10px;
    text-decoration: none;
  }
  
  .see-doctor-button-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 165%;
    height: 40px;
    background-color: #17BDB9;
    color: white;
    border-radius: 40px;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
  
  .see-doctor-icon-custom {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
  
  .secure-info-custom {
    background-color: #6A1B9A;
    width: 65%;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .secure-info-content-custom {
    padding: 10px;
    text-align: center;
  }
  
  .secure-info-title-custom {
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .logout-button-custom {
    background-color: #2196F3;
    color: white;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    padding: 3px 20px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
  }
  
  .nav-backdrop-custom {
    position: fixed;
    top: 0;
    left: calc(80% + 20px);
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
  }
  
  .nav-item-custom {
    display: flex;
    align-items: center;
    padding: 6px 6px;
    background-color: transparent;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .nav-item-custom:hover {
    background-color: #F2FFFF;
  }
  
  .nav-item-icon-custom {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
  
  .nav-item-text-custom {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
    color: #737791;
  }
  
  .nav-item-custom:hover .nav-item-text-custom {
    color: black;
  }
  