/* General container styling */
.current-hiv-information {
    font-family: 'Poppins', sans-serif;
    margin-left: 15%;
    margin-right: 15%;
    max-width: 600px;
}
.current-hiv-information .hiv-inf-h2{
    font-size: 1.22rem;
    font-weight: 500;
}

/* Heading styles */
.request-lab-container .mhs-h2 {
    font-weight: 500;
    font-size: 2rem;
    /* margin-top: 1em; */
    margin-bottom: 1.1rem;
}

.request-lab-container .add_info {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
    margin-top: 1.5em; 
}

/* Section styling */
.current-hiv-information .section {
    margin-bottom: 20px;
}

/* Label styling */
.current-hiv-information label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 300;
}

/* Input and Select styling */
.current-hiv-information input[type="checkbox"],
.current-hiv-information input[type="radio"] {
    margin-right: 10px;
}

.current-hiv-information input[type="text"],
.current-hiv-information select {
    padding: 8px;
    margin: 10px 1%;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Date selectors styling */
.current-hiv-information .date-selectors {
    display: flex;
    align-items: center;
    gap: 10px;
}

.current-hiv-information .date-selectors select {
    width: 30%;
}

/* Responsive styling */
@media (max-width: 600px) {
    .current-hiv-information {
        margin: auto;
    }

    .current-hiv-information h2 {
        font-size: 25px;
    }

    .current-hiv-information h3 {
        font-size: 18px;
    }

    .current-hiv-information label {
        font-size: 15px;
    }

    .current-hiv-information input[type="text"],
    .current-hiv-information select {
        font-size: 14px;
        width: 100%;
        margin: 10px 0;
    }

    .current-hiv-information .date-selectors {
        flex-direction: column;
        gap: 4px;
    }

    .current-hiv-information .date-selectors select {
        width: 100%;
        margin-bottom: 10px;
    }
}