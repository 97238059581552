.facility-selector-container {
    /* font-family: 'Poppins', sans-serif;
    max-width: 600px;
    margin-left: 15%;
    margin-right: 15%; */
    margin-bottom: 1rem;
    margin-top: 1rem;


}

.facility-label {
    display: block;
    font-size: 1.22rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.facility-select {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 300;
}

@media (max-width: 600px) {
    .facility-selector-container {
        margin: auto;
    }

    .facility-label {
        font-size: 18px;
    }

    .facility-select {
        font-size: 14px;
    }
}
