.content-container {
  margin-left: 20%; 
  margin-right: 5%; 
  width: 100%; 
  transition: margin-left 0.3s ease, width 0.3s ease; 
  margin-top: 6%;
}

/* Laptops (1400px and below) */
@media (max-width: 1400px) {
.content-container {
    margin-left: 25%;
    margin-right: 5%;
    margin-top: 10%;
}
}

/* Tablets (1000px and below) */
@media (max-width: 1000px) {
.content-container {
  margin-left: 0;
  margin-right: 0;
  margin-top: 10%;
}
}

/* Mobile landscape (600px to 1000px) */
@media (max-width: 600px) {
.content-container {
  margin-left: 10%;
  margin-right: 10%;
  width: 100%; 
  margin-top: 20%;
  margin-bottom: 100px;
}
}
