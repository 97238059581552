.find_service_container{
    margin-left: 15%;
    margin-right: 15%;
    font-family: poppins;
    margin-bottom: 5em;
    margin-top: 1em;
    max-width: 600px;
}

.find_service_container .input-field-search{
    width: 90%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.find_service_container .flex {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.find_service_container button {
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    background-color: #17bdb9;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    cursor: pointer;
}

.find_service_container button:hover {
    background-color: #139a96;
}
