.product-request-details {
    font-family: 'Poppins', sans-serif;
    margin-left: 15%;
    margin-right: 15%;
    max-width: 600px;
}

.product-request-details h2 {
    font-weight: 500;
    font-size: 2rem;
    margin-top: 1em;
    margin-bottom: 0.5rem;
}

.product-request-details h3 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
    margin-top: 1.5em;
}

.product-request-details .section {
    margin-bottom: 20px;
}

.product-request-details .section label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 300;
}

.product-request-details .section input[type="checkbox"],
.product-request-details .section input[type="radio"] {
    margin-right: 10px;
}

.product-request-details .section input[type="text"],
input[type="number"] {
    padding: 8px;
    margin: 10px 1%;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: calc(100% - 22px);
}

.quantity-field {
    margin-bottom: 15px;
}

.quantity-field label {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 5px;
    display: block;
}

@media (max-width: 600px) {
    .product-request-details {
        margin: auto;
    }

    .product-request-details h2 {
        font-size: 25px;
    }

    .product-request-details h3 {
        font-size: 18px;
    }

    .product-request-details label {
        font-size: 15px;
    }

    .product-request-details input[type="text"],
    .product-request-details input[type="number"] {
        font-size: 14px;
        width: 100%;
        margin: 10px 0;
    }
}
