.exposure-information {
    font-family: 'Poppins', sans-serif;
    margin-left: 15%;
    margin-right: 15%;
    max-width: 600px;
}

.exposure-information h2 {
    font-weight: 500;
    font-size: 2rem;
}

.exposure-information h3 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
    margin-top: 1.5em; 
}

.exposure-information .section {
    margin-bottom: 20px;
}

.exposure-information label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 300;
}

.exposure-information input[type="checkbox"] {
    margin-right: 10px;
}

.exposure-information input[type="text"],
.exposure-information select,
.exposure-information textarea {
    padding: 8px;
    margin: 10px 1%;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.exposure-information .date-selectors {
    display: flex;
    align-items: center;
    gap: 10px;
}

.exposure-information .date-selectors select {
    width: 30%;
}

.exposure-information textarea {
    width: 100%;
}

@media (max-width: 600px) {
    .exposure-information {
        margin: auto;
    }

    .exposure-information h2 {
        font-size: 25px;
    }

    .exposure-information h3 {
        font-size: 18px;
    }

    .exposure-information label {
        font-size: 15px;
    }

    .exposure-information input[type="text"],
    .exposure-information select,
    .exposure-information textarea {
        font-size: 14px;
        width: 100%;
        margin: 10px 0;
    }

    .exposure-information .date-selectors {
        flex-direction: column;
        gap: 4px;
    }

    .exposure-information .date-selectors select {
        width: 100%;
        margin-bottom: 10px;
    }
}
