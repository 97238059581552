.show-password-container {
    display: flex;
    align-items: center;
    margin-top: 15px; 
  }
  
  .custom-checkbox {
    width: 16px; 
    height: 16px; 
    cursor: pointer;
  }
  
  .custom-label {
    margin-left: 8px; 
    font-size: 0.875rem; 
  }
  