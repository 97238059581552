/* General page styling */
.success-page {
    position: fixed;
    z-index: 999;
    overflow: visible;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
}

/* Success card styling */
.success-card {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 4px 20px rgba(158, 158, 158, 0.1); 
    max-width: 500px;
    width: 100%;
    margin-right: 40%;
    margin-bottom: 10%;
}

/* Success logo container */
.success-logo-container {
    background-color: #E8D9FE;
    border-radius: 50%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 1rem;
    width: 80px;
    height: 80px;
}

/* Success logo styling */
.success-logo {
    width: 50px;
    height: 50px;
}

/* Heading styling */
.success-card h2 {
    margin-top: 1rem;
    font-size: 1.75rem;
    font-weight: 600;
    color: #044389; 
    font-family: 'Montserrat', sans-serif;
}

/* Paragraph styling */
.success-card p {
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 300;
    color: #838A93;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.5rem;
}

/* Button styling */
.success-card button {
    margin-top: 2.5rem;
    background-color: #044389; 
    color: #ffffff;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 50%;
}

/* Button hover effect */
.success-card button:hover {
    background-color: #044389; 
}

/* Responsive styling */
@media (min-width: 768px) {
    .success-card h2 {
        font-size: 2rem;
    }

    .success-card p {
        font-size: 1.125rem;
    }

    .success-card button {
        font-size: 1.125rem;
        padding: 1rem 3rem;
    }
}

@media (max-width: 600px) {
    .success-card {
        padding: 1.5rem;
        margin-right: 20%;
        margin-left: auto;
    }

    .success-card h2 {
        font-size: 1.5rem;
    }

    .success-card p {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    .success-logo-container {
        width: 60px;
        height: 60px;
    }

    .success-logo {
        width: 40px;
        height: 40px;
    }

    .success-card button {
        padding: 0.5rem 1.5rem;
    }
}
