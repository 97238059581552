.error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .error-message::before {
    content: "⚠️"; 
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
  }
  
  .error-message p {
    margin: 0;
    padding-left: 30px; 
  }
  