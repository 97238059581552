.loader {
    position: absolute;
    
  }
  
  .jimu-primary-loading:before,
  .jimu-primary-loading:after {
    position: absolute;
    content: '';
  }
  
  .jimu-primary-loading:before {
    left: -19.992px;
  }
  
  .jimu-primary-loading:after {
    left: 19.992px;
    -webkit-animation-delay: 0.32s !important;
    animation-delay: 0.32s !important;
  }
  
  .jimu-primary-loading:before,
  .jimu-primary-loading:after,
  .jimu-primary-loading {
    background: #fff;
    -webkit-animation: loading-keys-app-loading 0.8s infinite ease-in-out;
    animation: loading-keys-app-loading 0.8s infinite ease-in-out;
    width: 13.6px;
    height: 32px;
  }
  
  .jimu-primary-loading {
    text-indent: -9999em;
    margin: auto;
    position: absolute;
    right: calc(50% - 6.8px);
    top: calc(50% - 16px);
    -webkit-animation-delay: 0.16s !important;
    animation-delay: 0.16s !important;
  }
  
  @-webkit-keyframes loading-keys-app-loading {
  
    0%,
    80%,
    100% {
      opacity: .95;
      box-shadow: 0 0 #fff;
      height: 20px;
    }
  
    40% {
      opacity: 1;
      box-shadow: 0 -8px #fff;
      height: 20px;
    }
  }
  
  @keyframes loading-keys-app-loading {
  
    0%,
    80%,
    100% {
      opacity: .75;
      box-shadow: 0 0 #fff;
      height: 20px;
    }
  
    40% {
      opacity: 1;
      box-shadow: 0 -8px #fff;
      height: 28px;
    }
  }