.medication-container {
    margin-left: 15%;
    margin-top: 5%;
    margin-right: 10%;
    /* padding-left: 10%; */
  }
  
 /* Media queries for responsiveness */

  @media (max-width: 768px) {
    .medication-container {
      margin-left: -5%;
      width: 100%;
      
    }
  }
  