.request-lab-container .select-dropdown {
    position: relative;
    width: 100%;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    margin-bottom: 1rem;
}

.request-lab-container .select-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;

    border: 3px solid #D9D9D9;
    border-radius: 10px;
    cursor: pointer;
}

.request-lab-container .select-input span {
    flex-grow: 1;
}

.request-lab-container .select-input .arrow-icon, 
.request-lab-container .select-input .hospital-icon{
    margin-left: 10px;
}

.request-lab-container .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border-top: none;
    z-index: 1;
}

.request-lab-container .dropdown-list li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #D9D9D9;
}

.request-lab-container .dropdown-list li:hover {
    background-color: #D9D9D9;
}