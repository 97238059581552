.personal-update-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  padding: 0 15%;
  box-sizing: border-box;
}
.personal-update-popup-container.closed{
  display: none;
}

.personal-update-popup {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}
.personal-warning-message-title{
  color: #dc3545;
}

.personal-update-title {
  margin-bottom: 10px;
  font-size: 1.9em;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.personal-update-message {
  margin-bottom: 20px;
  font-size: 1em;
  color: #333;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.personal-update-button-group {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.personal-update-button,
.personal-remind-me-later-button {
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  width: calc(50% - 10px);
  /* Adjust to ensure two buttons fit in one row */
  font-family: 'Poppins', sans-serif;
  background-color: #e7d7ff;
  margin-top: 2rem;
  color: #8A3FFC;
  border: 3px solid #8A3FFC;
  transition: background-color 0.3s, border-color 0.3s;

}

.personal-remind-me-later-button:hover {
  background-color: #8A3FFC; 
  color: #e7d7ff; 
  border-color: #8A3FFC; 
}
.personal-update-button:hover {
  background-color: #8A3FFC; 
  color: #e7d7ff; 
  border-color: #8A3FFC; 
}


.personal-thank-you-message {
  font-size: 0.9em;
  color: #666;
}

.personal-warning-message {
  position: fixed;
  top: 30%;
  left: 60%;
  transform: translate(-50%, -50%);
  color: #dc3545;
  padding: 15px 25px;
  border-radius: 8px;
  max-width: 90%;
  text-align: center;
  font-size: 1em;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  z-index: 1000;
  border: 2px solid #fff;
  background-color: #fff;
  opacity: 1;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}


@media (max-width: 768px) {
  .personal-update-popup {
    padding: 15px;
    max-width: 90%;
  }

  .personal-update-title {
    font-size: 1.5em;
  }

  .personal-update-message,
  .personal-thank-you-message {
    font-size: 0.9em;
  }

  .personal-update-button,
  .personal-remind-me-later-button {
    width: 100%;
    margin: 5px 0;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .personal-update-popup {
    padding: 10px;
  }

  .personal-update-title {
    font-size: 1.2em;
  }

  .personal-update-message,
  .personal-thank-you-message {
    font-size: 0.8em;
  }

  .personal-update-button,
  .personal-remind-me-later-button {
    padding: 8px;
    font-size: 0.9em;
  }
}