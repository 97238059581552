/* PhoneVerificationPopup.css */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 90%;
  }
  
  .popup-content h1 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .popup-content p {
    margin-bottom: 20px;
  }
  
  .popup-button {
    background: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popup-button:hover {
    background: #45a049;
  }
  