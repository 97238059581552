/* General Styles */
.email-verification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f7f7f7;
  padding: 1rem;
}

/* Box Styling */
.email-verification-box {
  background-color: #ffffff;
  padding: 3rem; /* Increased padding for a more spacious feel */
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px; /* Increased the max width */
  text-align: center;
}

/* Typography */
.email-verification-title {
  font-size: 2rem; /* Slightly increased font size for title */
  margin-bottom: 1.5rem;
  color: #333333;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.email-verification-text {
  font-size: 1.125rem; /* Slightly increased font size for text */
  margin-bottom: 3.5rem; /* Increased margin for better spacing */
  color: #555555;
  font-family: 'Open Sans', sans-serif;
}

.email-verification-subtext {
  font-size: 1rem;
  color: #888888;
  margin-bottom: 1.25rem;
  font-family: 'Open Sans', sans-serif;
}

/* Button Sections */
.email-verification-options {
  display: flex;
  flex-direction: column;
  gap: 2rem; /* Increased gap between buttons */
  margin-top: 2.5rem; /* Increased margin for better spacing */
}

.email-verification-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Horizontal Layout for Large Screens */
@media (min-width: 768px) {
  .email-verification-options {
    flex-direction: row;
    justify-content: center;
  }

  .email-verification-section {
    align-items: flex-start;
  }
}

/* Button Styles */
.email-verification-option {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem; /* Increased font size for buttons */
  font-weight: 500;
  padding: 1rem 1.5rem; /* Increased padding for larger buttons */
  border-radius: 8px;
  border: none;
  transition: all 0.3s ease;
  cursor: pointer;
  min-width: 200px; /* Added a minimum width to the buttons */
}

/* Contact Us Button */
.contact-us {
  background-color: #ffffff;
  color: #044389;
  border: 2px solid #044389;
}

.contact-us:hover {
  background-color: #f0eaff;
  color: #044389;
}

/* Resend Confirmation Email Button */
.resend-email {
  background-color: #044389;
  color: #ffffff;
  box-shadow: 0 4px 10px rgba(138, 63, 252, 0.3);
}

.resend-email:hover {
  background-color: #FC9E4F;
  box-shadow: 0 6px 12px rgba(138, 63, 252, 0.5);
}

/* Disabled State for Resend Button */
.resend-email:disabled {
  background-color: #044389;
  opacity: 0.6;
  cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 480px) {
  .email-verification-box {
    padding: 2rem; /* Reduced padding for smaller screens */
  }

  .email-verification-title {
    font-size: 1.75rem; /* Slightly smaller title for smaller screens */
  }

  .email-verification-text {
    font-size: 1rem;
  }

  .email-verification-subtext {
    font-size: 0.875rem;
  }

  .email-verification-options {
    gap: 1rem;
  }

  .email-verification-option {
    padding: 0.75rem 1.25rem;
  }
}
