/* Header Background */
.header-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 4px 6px rgba(195, 195, 195, 0.1);
    z-index: 100;
    
  }
  
  /* Header Content */
  .header-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px 20px; /* Reduced padding for mobile */
    display: flex;
    justify-content: space-between;
    align-items: center;
   
  }
  
  /* Logo Container */
  .logo-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .logo {
    width: 100px;
    height: auto;
    display: none;
  }
  
  /* Profile Container */
  .profile-container {
    display: flex;
    align-items: center;
  }
  
  .profile-image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  
  .profile-name {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #8a3ffc;
    margin-left: 10px;
    white-space: nowrap;
  }

    /* Laptops (1400px and below) */
@media (max-width: 1400px) {

  .header-content {
    padding: 12px 95px; 
  }


}
  
  /* Mobile adjustments */
  @media (max-width: 600px) {
    .header-content {
      padding: 12px 30px; 
    }
  
    .profile-name {
      font-size: 14px;
    }

    .logo {
      width: 90px;
      height: auto;
      display: inline;
    }
  }

  @media (max-width: 1000px){
    .logo {
      width: 100px;
      height: auto;
      display: inline;
    }
  }



  