.registration-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  font-family: Poppins, sans-serif;
  padding: 2%;
  box-sizing: border-box;
}

.registration-container .registration-form {
  width: 40%;
  margin-left: 5%;
}

.registration-container .registration-form label {
  font-size: 14px;
}

.registration-image,
.login-image {
  width: 50%;
  padding: 2rem;
  box-sizing: border-box;
  margin-left: 5%;
}

.error-message {
  color: red;
  font-size: 15px;
  font-family: Poppins, sans-serif;
}

.registration-form {
  padding-left: 5%;
  padding-right: 8%;
}

.registration-container .nav-back {
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  cursor: pointer;
}

.registration-container .nav-back img {
  width: 15px;
  margin-right: 0.2em;
}

.registration-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.registration-form h2 {
  margin-bottom: 0.5rem;
  font-size: 25px;
  font-weight: 600;
}

.registration-form p {
  margin-bottom: 1rem;
  font-size: 15px;
  font-weight: 400;
}

.registration-form label {
  margin-bottom: 0.2em;
  font-size: 15px;
}

.registration-container form div {
  display: block;
}

.registration-container form button div {
  display: flex;
  margin-bottom: 0;
}

/* .registration-form input {DDDDDDDDD */

.registration-form input {
  width: 100%;
  padding: 0.5rem;
  background-color: #FBFFF1;
  border: 1px solid #044389 !important;
  height: 40px;
  border-radius: 5px;
  font-size:15px;
  font-weight:500;
  font-family:poppins;

}

.registration-form input:focus {
  border: 1px solid #6EC1F2  !important;
  outline: #6EC1F2;
}

.registration-form button {
  /* background: #6EC1F2; */
  color: white;
  border: none;
  width: 100%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  height: 50px;
  border-radius: 5px;
  margin-top:1rem;
}

.registration-form  .m-0 {
  background: #6EC1F2;
  
}

.registration-form .inactive-button {
  background-color: rgb(236, 236, 236);
  color: white;
  cursor: not-allowed;
}

.registration-form .active-button {
  background: #6EC1F2;
  color: white;
  cursor: pointer;

}

.registration-form button.inactive{
  background-color: rgb(243, 239, 239);
  cursor: not-allowed;

}

.registration-form button.active {
  background: #6EC1F2;
}

.registration-form .already {
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}

.registration-form .already span {
  color: #1e4ae9;
}

.registration-container .cop-right {
  bottom: 0;
  padding-bottom: 20px;
  margin-left: -40px;
  margin-top: 20px;
  display: none;
}

.registration-container .cop-right ul {
  display: flex;
  justify-content: space-evenly;
  font-size: 12px;
  font-weight: 400;
}

.registration-image,
.login-image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../images/registration.jpg');
  background-size: cover;
  background-position: center;
  border-radius: 20px;
}

.login-image {
  background-image: url('../images/login.jpeg');
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: #8a3ffca3; */
  background-color: #044389a3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.overlay h3 {
  color: #FBFFF1;
  margin-top: auto;
  text-align: center;
  justify-content: center;
  font-size: 40px;
  font-weight: 600;
}

.overlay img {
  margin-left: auto;
  margin-top: auto;
  padding-bottom: 5%;
  padding-right: 5%;
  width: 7em;
}

/* Password Checkbox Style */
.registration-form .show-password-container {
  display: flex;
  align-items: center; 
  margin-bottom: 1em;
  font-size: 14px;
}

.registration-form .show-password-container input[type="checkbox"] {
  width: 16px; 
  height: 16px;
  margin-right: 0.5em; 
  cursor: pointer;
}

.registration-form .show-password-container label {
  margin: 0; 
  font-size: 14px; 
  cursor: pointer; 
  line-height: 1.5; 
}


@media only screen and (max-width: 600px) {
  .registration-container {
    flex-direction: column-reverse;
    height: unset;
  }

  .registration-container .registration-form {
    width: 100%;
    margin: 0;
    padding: 5%;
  }

  .login-image {
    height: 20em;
    width: 100%;
    margin-left: -3px;
    margin-bottom: 2rem;
  }

  .cop-right,
  .registration-image {
    display: none;
  }

  .overlay h3 {
    font-size: 30px;
  }

  .registration-container .nav-back {
    margin-bottom: 15px;
    margin-top: 20px;
  }
}

  /* Tablets (1024px and below) */
  @media (max-width: 1024px) {

    .registration-container {
      flex-direction: column-reverse;
      height: unset;
    }
  
    .registration-container .registration-form {
      width: 100%;
      margin: 0;
      padding: 5%;
    }
  
    .login-image {
      height: 20em;
      width: 100%;
      margin-left: -3px;
      margin-bottom: 2rem;
    }
  
    .cop-right,
    .registration-image {
      display: none;
    }
  
    .overlay h3 {
      font-size: 30px;
    }
  
    .registration-container .nav-back {
      margin-bottom: 15px;
      margin-top: 20px;
    }

  
  }
