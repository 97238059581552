/* Container for the entire summary page */
.prep-summary-page-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Card container for each section */
  .prep-summary-section {
    background-color: #ffffff;
    border-radius: 10px;
    border: 0.1px solid #daeafb;
    /* box-shadow: 0 0px 10px rgba(101, 101, 101, 0.1); */
    padding: 20px;
    margin-bottom: 10px;
  }
  
  /* Title for the entire summary page */
  .prep-summary-page-container h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: 500;
    text-align: left;
    color: #333;
  }
  
  /* Section titles within each card */
  .prep-summary-section h3 {
    font-size: 1.55rem;
    font-weight: 500;
    margin-bottom: 1rem;
    color: #090909;
  }
  
  /* Content within each card */
  .prep-summary-section p {
    font-size: 16px;
    margin: 5px 0;
    color: #666;
    font-weight: 400;
  }
  
  /* Submit button */
  .prep-submit-button {
    display: block;
    width: 100%;
    padding: 10px 20px;
    font-size: 18px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }
  
  .prep-submit-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .prep-summary-page-container {
      padding: 15px;
    }
  
    .prep-summary-section h3 {
      font-size: 18px;
    }
  
    .prep-summary-section p {
      font-size: 15px;
    }
  
    .prep-submit-button {
      font-size: 16px;
      padding: 8px 15px;
    }
  }
  
  @media (max-width: 480px) {
    .prep-summary-page-container {
      padding: 10px;
      width: 100%;
    }
  
    .prep-summary-section {
      background-color: transparent; 
      /* box-shadow: none;  */
      padding: 10px;
      border-radius: 0; 
      width: 100%;
    }
  
    .prep-summary-section h3 {
      font-size: 18px;
    }
  
    .prep-summary-section p {
      font-size: 16px;
    }
  
    .prep-submit-button {
      font-size: 16px;
      padding: 10px 15px;
    }
  }
  