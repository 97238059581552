/* Dashboard Container */
.dashboard-container {
  width: auto;
  /* padding: 20px; */
  /* margin-left: 10%; */
  /* margin-right: 10%; */
  /* background-color: #f5f5f5;  */
}

/* Dashboard Content Layout */
.dashboard-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Welcome Section Styling */
.welcome-section {
  margin-bottom: 20px;
}

/* HivServeLinks Section Styling */
.hiv-serve-links-section {
  margin-bottom: 20px;
}

/* Responsive Design for Tablets and Larger Screens */
@media (min-width: 768px) {
  .dashboard-content {
      flex-direction: column; 
      gap: 40px;
  }

  .welcome-section, 
  .hiv-serve-links-section {
      flex: 1;
  }
}

/* Responsive Design for Mobile Screens (767px and below) */
@media (max-width: 767px) {
  .dashboard-container {
      width: 100%;  
      margin-left: 0;  
      margin-right: 0;  
      padding: 0;  
  }
}
