/* General container styling */
.delivery-date-time {
    font-family: 'Poppins', sans-serif;
    max-width: 600px;
    width: 100%;
    margin: 0 auto; /* Center the container */
}

/* Heading styles */
.delivery-date-time h3 {
    font-size: 1.22rem;
    font-weight: 500;
    margin-bottom: 1rem;
    margin-top: 1.5em; 
}

/* Section styling */
.delivery-date-time .section {
    margin-bottom: 20px;
}

/* Date and Time selectors styling */
.delivery-date-time .date-selectors,
.delivery-date-time .time-selectors {
    display: flex;
    gap: 20px; /* Space between each label-select pair */
}

/* Label and Select wrapper */
.delivery-date-time .input-group {
    display: flex;
    flex-direction: column;
    flex: 1;
}

/* Label styling */
.delivery-date-time label {
    font-size: 14px;
    margin-bottom: 4px; /* Space between label and select */
    font-weight: 500; /* Slightly bolder text for labels */
    color: #333; /* Darker color for better readability */
}

/* Select input styling */
.delivery-date-time select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%; /* Full width of the container */
}

/* Responsive styling */
@media (max-width: 600px) {
    .delivery-date-time {
        margin: auto;
    }

    .delivery-date-time h3 {
        font-size: 20px;
    }

    .delivery-date-time label {
        font-size: 14px;
        text-align: left;
    }

    .delivery-date-time .date-selectors,
    .delivery-date-time .time-selectors {
        flex-direction: column; /* Stack labels and selects vertically on small screens */
        gap: 8px;
    }

    .delivery-date-time select {
        font-size: 14px;
        width: 100%;
    }
}
